import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import moment from 'moment';
import { MenuPage } from 'src/app/modals/menu/menu.page';
import { Establishment, EstablishmentFull, LikeType } from 'src/app/models';
import { ApiService } from 'src/app/services';
import { AuthService } from 'src/app/services';
import { CreateBookingPage } from '../create-booking/create-booking.page';
import { UtilsService } from 'src/app/services/utils';
import { VacancyPage } from '../vacancy/vacancy.page';
import { VacanciesPage } from '../vacancies/vacancies.page';


import { SwipeService } from 'src/app/services/swipe';
import { register } from 'swiper/element/bundle';
import { environment } from 'src/environments/environment';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
register();

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.page.html',
  styleUrls: ['./restaurant.page.scss'],
  providers: [SwipeService]
})
export class RestaurantPage implements OnInit {
  staticHostUrl = environment.staticHostUrl;
  restaurant: EstablishmentFull;
  restaurants: Establishment[];
  header: String = '';
  vacancies: any = [];

  timeString = '';
  restaurantLike = 0;
  saved = false;

  userId = '';
  showWorkingHours = false;
  isOpen = false;

  restIndex = 0;

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private socialsharing:SocialSharing,
    private alertCtrl: AlertController,
    private swipeService: SwipeService,
    private utils: UtilsService
  ) {
  }

  ionViewWillEnter() {

  }

  async ngOnInit() {
    console.log('restaurant', this.restaurant);
    console.log('restaurants', this.restaurants);

    await this.authService.user$.subscribe(res => {
      this.userId = res.attributes.sub;
    })

    this.getRestaurant();
  }

  async getRestaurant() {
    this.apiService.getEstablishment(this.restaurant.id).subscribe(async restaurant => {
      this.restaurant = restaurant;

      this.timeString = '';
      this.saved = false;
      this.restaurantLike = 0;

      let userLike = await this.restaurant.likes.filter(like => like.userId == this.userId);
      if (userLike.length > 0) {
        this.restaurantLike = userLike[0].type;
      } else {
        this.restaurantLike = 0;
      }

      let userSaved = await this.restaurant.saves.filter(save => save.userId == this.userId);
      if (userSaved.length > 0) {
        this.saved = true;
      } else {
        this.saved = false;
      }

      const format = 'hh:mm';

      const time = moment(moment(), format);
      const beforeTime = moment(restaurant.openFrom, format);
      const afterTime = moment(restaurant.openTill, format);

      this.isOpen = time.isBetween(beforeTime, afterTime);
    });

    (await this.apiService.getVacancies(this.restaurant.id)).subscribe(result => {
      this.vacancies = result.jobOpenings;
    })
  }

  async like() {
    if (!this.userId) return this.utils.showAuthAlert();

    if (this.restaurantLike === 1) {
      this.deleteLike();
    } else {
      await this.apiService.like(this.restaurant.id, LikeType.Like);
      this.restaurantLike = LikeType.Like;
    }
  }

  async dislike() {
    if (!this.userId) return this.utils.showAuthAlert();

    await this.apiService.like(this.restaurant.id, LikeType.Dislike);
    this.restaurantLike = LikeType.Dislike;
  }

  async deleteLike() {
    this.apiService.deleteLike(this.restaurant.id);
    this.restaurantLike = 0;
  }

  async save() {
    if (!this.userId) return this.utils.showAuthAlert();

    await this.apiService.save(this.restaurant.id);
    this.saved = true;
  }

  async deleteSave() {
    await this.apiService.deleteSave(this.restaurant.id);
    this.saved = false;
  }

  async openRestaurant(restaurant, index) {
    let cssClass = 'restaurantModal modalBottom modalContent';

    if (window.screen.width < 600) {
      cssClass = 'mobileModal'
    }

    const modal = await this.modalCtrl.create({
      componentProps: {
        restaurant: restaurant,
        restaurants: this.restaurant.similarPlaces,
        restIndex: index,
        header: 'Схожі на ' + this.restaurant.name
      },
      component: RestaurantPage,
      cssClass: cssClass
    });

    await modal.present();
  }

  async openMenu() {

    if (!this.restaurant.featureState?.activated) {
      this.utils.showAlert(this.restaurant.name, 'Якщо це Ваш заклад, зв’яжіться з нами, щоб активувати цей сервіс. Це абсолютно безкоштовно');
      return;
    }

    this.apiService.getMenu(this.restaurant.id).subscribe(async menu => {

      /*
      
      let cssClass = 'modalBottom modalContent';

      if (window.screen.width < 600) {
        cssClass = 'mobileModal'
      }
      */

      let cssClass = '';


      if (window.screen.width < 600) {
        cssClass = 'mobileModal'
      } else {
        cssClass = 'modalBottom modalContent menuModal lightBg'
      }

      const modal = await this.modalCtrl.create({
        componentProps: {
          menu: menu,
          restaurant: this.restaurant
        },
        component: MenuPage,
        cssClass: cssClass
      });

      this.saveMenu();

      await modal.present();
    })
  }

  async openCreateBooking() {
    let cssClass = 'modalBottom modalContent';

    if (window.screen.width < 600) {
      cssClass = 'mobileModal'
    }

    const modal = await this.modalCtrl.create({
      componentProps: {
        restaurant: this.restaurant
      },
      component: CreateBookingPage,
      cssClass: cssClass
    });

    await modal.present();
  }

  async openVacancies() {

    if (!this.restaurant.featureState?.activated) {
      this.utils.showAlert(this.restaurant.name, 'Якщо це Ваш заклад, зв’яжіться з нами, щоб активувати цей сервіс. Це абсолютно безкоштовно');
      return;
    }

    let cssClass = 'modalBottom modalContent vacancyModal';

    if (window.screen.width < 600) {
      cssClass = 'mobileModal'
    }

    const modal = await this.modalCtrl.create({
      componentProps: {
        vacancies: this.vacancies
      },
      component: VacanciesPage,
      cssClass: cssClass
    });

    await modal.present();
  }

  async saveMenu() {

    let menus = JSON.parse(localStorage.getItem('menus'));
    let menuIsset = await menus.filter(menu => menu.id == this.restaurant.id);

    if (menuIsset.length == 0) {
      menus.push(this.restaurant);
      this.restaurant.added = moment().toISOString();
      localStorage.setItem('menus', JSON.stringify(menus));
    }
  }

  prev() {
    if (this.restIndex > 0) {
      this.restIndex--;
      this.restaurant.id = this.restaurants[this.restIndex].id;
      this.getRestaurant();
    } else {
      this.modalCtrl.dismiss();
    }
  }

  next() {
    if (this.restIndex < this.restaurants.length - 1) {
      this.restIndex++;
      this.restaurant.id = this.restaurants[this.restIndex].id;
      this.getRestaurant();
    }
  }

  async share() {

    this.socialsharing.share(`https://restaapp.com/restaurant_page/${this.restaurant.id}`).then(res => {
      
    })

    //navigator.clipboard.writeText('https://restaapp.com/restaurant_page/' + this.restaurant.id);
    //await (await this.toastCtrl.create({ message: 'Посилання на заклад скопійвано', duration: 1500 })).present();
  }



  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    }
    else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;
      if (duration < 1000
        && Math.abs(direction[0]) > 30
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        if (direction[0] < 0) {
          this.next();
        } else {
          //previous
          this.prev();
        }
      }
    }
  }

}
