import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Establishment } from 'src/app/models';
import { NewReservation } from 'src/app/models/new-reservation';
import { Slot } from 'src/app/models/slot.model copy';
import { ApiService } from 'src/app/services';
import { UtilsService } from 'src/app/services/utils';

@Component({
  selector: 'app-create-booking',
  templateUrl: './create-booking.page.html',
  styleUrls: ['./create-booking.page.scss'],
})
export class CreateBookingPage implements OnInit {

  newReservation:NewReservation = {
    firstName:'',
    lastName:'',
    phone:'',
    at:'',
    guests:null,
    comment:''
  };

  date: string;

  restaurant!: Establishment;
  slots: Slot[];

  constructor(public modalCtrl: ModalController, private apiService: ApiService, private utils:UtilsService) { }

  getSlots() {
    this.slots = [];
    this.newReservation.at = null;

    if (this.date != '' && this.newReservation.guests > 0) {
      this.apiService.getSlots(this.restaurant.id, this.date, this.newReservation.guests).subscribe(slots => {
        this.slots = slots;
      })
    }
  }

  createReservation(){
    console.log(this.newReservation);
    if(
      !this.newReservation.firstName 
      || !this.newReservation.lastName 
      || !this.newReservation.phone
      || !this.newReservation.at
      || !this.newReservation.guests
    ){
      //TODO ERROR ALERT
    }

    this.apiService.createReservation(this.restaurant.id, this.newReservation).then(res => {
      console.log(res);
    })
  }

  ngOnInit() {
  }

}
