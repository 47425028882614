import { InputMaskModule } from '@ngneat/input-mask';
import { Amplify } from '@aws-amplify/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { CreateBookingPageModule } from './modals/create-booking/create-booking.module';
import { MenuPageModule } from './modals/menu/menu.module';
import { RestaurantPageModule } from './modals/restaurant/restaurant.module';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { BookingPageModule } from './modals/booking/booking.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    RestaurantPageModule,
    MenuPageModule,
    CreateBookingPageModule,
    BookingPageModule,
    InputMaskModule,
    BrowserAnimationsModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, Geolocation, SocialSharing],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {
    Amplify.configure(environment.amplify);
  }
}
