import { Component, OnInit } from '@angular/core';
import { VacancyPage } from '../vacancy/vacancy.page';
import { ModalController } from '@ionic/angular';
import colors from '../../../assets/mockdata/colors.json';

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.page.html',
  styleUrls: ['./vacancies.page.scss'],
})
export class VacanciesPage implements OnInit {

  colors = colors;
  vacancies;

  constructor(
    public modalCtrl:ModalController
  ) { }

  ngOnInit() {
  }

  async openVacancy(vacancy) {
    let cssClass = 'modalBottom modalContent vacancyModal';

    if (window.screen.width < 600) {
      cssClass = 'mobileModal'
    }

    const modal = await this.modalCtrl.create({
      componentProps: {
        vacancy: vacancy
      },
      component: VacancyPage,
      cssClass: cssClass
    });

    await modal.present();
  }

}
