import { Component, NgZone, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { createMask } from '@ngneat/input-mask';
import moment from 'moment';
import { ApiService, AuthService, UtilsService } from 'src/app/services';

@Component({
  selector: 'app-vacancy',
  templateUrl: './vacancy.page.html',
  styleUrls: ['./vacancy.page.scss'],
})
export class VacancyPage implements OnInit {
  
  phoneMask = createMask('+38(099) 999-99-99');
  dateMask = createMask('99.99.9999');

  vacancy!: any;

  data: any = {
    comment: '',
    reusableUserProfile: true,
    userProfile: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      birthDate: '',
      pastJobs: {
        companyName: '',
        town: '',
        position: '',
        yearFrom: '',
        yearTill: ''
      }
    }
  }

  save = true;

  constructor(
    private apiService: ApiService,
    public modalCtrl: ModalController,
    private auth: AuthService,
    private ngZone: NgZone,
    private utils: UtilsService
  ) {
    this.getAppl();
    this.getData();
  }

  async getData() {
    this.auth.user$.subscribe((user: any) => {

      this.data.userProfile.firstName = user.attributes.given_name;
      this.data.userProfile.lastName = user.attributes.family_name;
      this.data.userProfile.email = user.attributes.email;

      this.getInfo();
    })
  }

  async getInfo() {
    (await this.apiService.getStoredUserProfile()).subscribe((result) => {
      console.log('result', result);

      this.ngZone.run(() => {
        this.data.userProfile.firstname = result.firstName;
        this.data.userProfile.lastName = result.lastName;
        this.data.userProfile.email = result.email;
        this.data.userProfile.phone = result.phone;
        this.data.userProfile.birthDate = result.birthDate;
        this.data.userProfile.pastJobs = result.pastJobs;
      })
    })
  }

  async getAppl() {
    (await this.apiService.getApplications()).subscribe(async res => {
      (await this.apiService.getApplication(res.jobApplications[0].id)).subscribe(data => {
      })
    })
  }

  async send() {

    if(
      this.data.userProfile.firstname == '' ||
      this.data.userProfile.lastName == '' ||
      this.data.userProfile.email == '' ||
      this.data.userProfile.phone == '' ||
      this.data.comment == ''
    ){
      this.utils.showError("Заповніть усі обов'язкові поля");
      return;
    }

    (await this.apiService.sendVacancy(this.vacancy.establishment.id, this.vacancy.id, this.data)).subscribe(res => {
      this.utils.showAlert("Дякуємо!", "Ми отримали Ваше звернення та зв'яжемося з Вами в найближчий час, якщо Ваша заявка відповідає нашим вимогам. ")
      this.modalCtrl.dismiss();

      console.log(res);
    }, err => {
      console.log(err.error)
      if(err.error.message == 'Application for this opening has been already supplied.'){
        this.utils.showError("Ви вже відправили відгук на цю вакансію")
      } else {
        this.utils.showError("Виникла помилка. Будь ласка спробуйте пізніше")
      }
    })
  }

  ngOnInit() {
  }

}
