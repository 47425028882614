import { Observable, mergeMap, map, iif, defer, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Establishment, EstablishmentFull, FeedCategory, LikeType, MyListItem, UnauthenticatedError } from '../../models';
import { AuthService } from '../auth';
import { EstablishmentMap } from 'src/app/models/establishment-map.model';
import { Config } from 'src/app/models/config.model';
import { Slot } from 'src/app/models/slot.model copy';
import { NewReservation } from 'src/app/models/new-reservation';
import { Booking } from 'src/app/models/booking';
import { time } from 'console';

const HOME_FEED_URL = '/home';
const LIKE_URL = '/likes/like';
const DISLIKE_URL = '/likes/dislike';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  headers$ = this.authService.accessToken$.pipe(
    map((at: string) => ({
      ...(!!at && {
        Authorization: `Bearer ${at}`,
      }),
    })),
  );

  headersAuthorized$ = this.authService.accessToken$.pipe(
    mergeMap((at: string) => iif(
      () => !!at,
      defer(() => this.headers$),
      defer(() => throwError(() => new UnauthenticatedError('Unauthenticated'))),
    )),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
  ) { }


  getConfig(): Observable<Config> {
    const url = `${environment.apiHostUrl}/config`;
    return this.http.get<Config>(url).pipe(
      map((config) => config),
    );
  }

  getHomeFeed(): Observable<FeedCategory[]> {
    const url = `${environment.apiHostUrl}${HOME_FEED_URL}`;
    return this.http.get<any>(url).pipe(
      map(({ categories }) => categories),
    );
  }

  getHomeFeedCategory(id: string, offset:number = 0): Observable<Establishment[]> {
    const url = `${environment.apiHostUrl}${HOME_FEED_URL}/category/${id}?offset=${offset}&limit=20`;
    return this.http.get<any>(url).pipe(
      map(({ places }) => places),
    );
  }

  getMapFeed(lat1: number, lng1: number, lat2: number, lng2: number, term: string = '', typeId: string = '', cuisineId: string = '', occasionId: string = ''): Observable<EstablishmentMap[]> {

    let params: any = {
      lat1: lat1,
      lng1: lng1,
      lat2: lat2,
      lng2: lng2
    }

    if (typeId != '') params.typeId = typeId;
    if (cuisineId != '') params.cuisineId = cuisineId;
    if (occasionId != '') params.occasionId = occasionId;
    if (term != '') params.term = term;

    const url = `${environment.apiHostUrl}/map`;
    return this.http.get<any>(url, { params: params }).pipe(
      map(({ establishments }) => establishments),
    );
  }

  getEstablishment(id: string): Observable<EstablishmentFull> {
    const url = `${environment.apiHostUrl}/establishments/${id}`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<EstablishmentFull>(url, { headers })),
    );
  }

  getMenu(id: string): Observable<any> {
    const url = `${environment.apiHostUrl}/establishment/${id}/menu`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<any>(url, { headers })),
    );
  }

  async like(estId: string, type: LikeType) {
    const path = type === LikeType.Like ? LIKE_URL : DISLIKE_URL;
    console.log(`${environment.apiHostUrl}${path}/${estId}`);
    let token = '';

    this.authService.accessToken$.subscribe(res => { token = res; });

    console.log(token)
    this.http.put(`${environment.apiHostUrl}${path}/${estId}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  async deleteLike(estId: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    this.http.delete(`${environment.apiHostUrl}/likes/${estId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  async likeItem(itemId: string, type: LikeType) {
    const path = type === LikeType.Like ? LIKE_URL : DISLIKE_URL;
    let token = '';

    this.authService.accessToken$.subscribe(res => { token = res; });

    console.log(token)
    this.http.put(`${environment.apiHostUrl}${path}/menu-item/${itemId}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  async deleteLikeItem(itemId: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    this.http.delete(`${environment.apiHostUrl}/likes/menu-item/${itemId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  async saveItem(itemId: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    this.http.put(`${environment.apiHostUrl}/saves/menu-item/${itemId}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  async deleteSaveItem(itemId: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    await this.http.delete(`${environment.apiHostUrl}/saves/menu-item/${itemId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  likeOld(estId: string, type: LikeType) {
    try {
      return this.headersAuthorized$.pipe(
        mergeMap((headers) => {
          const path = type === LikeType.Like ? LIKE_URL : DISLIKE_URL;
          return this.http.put(`${environment.apiHostUrl}${path}/${estId}`, {}, { headers });
        }, err => {
          console.log(err)
        }),
      );
    } catch (err) {
      console.log(err);
    }
  }

  async save(estId: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    this.http.put(`${environment.apiHostUrl}/saves/${estId}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  async deleteSave(estId: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    await this.http.delete(`${environment.apiHostUrl}/saves/${estId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  async updateComment(estId: string, comment: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    return this.http.post(`${environment.apiHostUrl}/saves/${estId}/comment`, {
      message: comment
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  async updateCommentLike(likeId: string, comment: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    return this.http.post(`${environment.apiHostUrl}/likes/${likeId}/comment`, {
      message: comment
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  getSlots(estId: string, date: string, guests: number): Observable<Slot[]> {
    const url = `${environment.apiHostUrl}/${estId}/reservations/slots?date=${date}&guests=${guests}`;
    return this.http.get<{ slots: Slot[] }>(url).pipe(
      map(({ slots }) => slots),
    );
  }

  async createReservation(estId: string, newReservation: NewReservation) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    return this.http.post(`${environment.apiHostUrl}/${estId}/reservations`, newReservation, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  getQuickChoice(genre: string): Observable<Establishment[]> {
    const url = `${environment.apiHostUrl}/establishments/quick-choices/${genre}`;
    return this.http.get<{ establishments: Establishment[] }>(url).pipe(
      map(({ establishments }) => establishments),
    );
  }

  search(term: string, typeId: string = '', cuisineId: string = '', occasionId: string = '', neighborhoodId: string = ''): Observable<Establishment[]> {

    let params: any = {};

    if (term != '') params.term = term;
    if (typeId != '') params.typeId = typeId;
    if (cuisineId != '') params.cuisineId = cuisineId;
    if (occasionId != '') params.occasionId = occasionId;
    if (neighborhoodId != '') params.neighborhoodId = neighborhoodId;

    return this.http.get<{ establishments: Establishment[] }>(`${environment.apiHostUrl}/search`, { params: params }).pipe(
      map(({ establishments }) => establishments),
    );
  }

  async getMyLists_old(): Promise<any> {
    let token = '';

    await this.authService.accessToken$.subscribe(async res => {
      token = res;

      console.log(token);

      if (token) {

        return this.http.get<{ list: MyListItem[] }>(`${environment.apiHostUrl}/my-lists`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).pipe(
          map(({ list }) => { list }),
        );
      }
    });
  }

  async getMyLists(): Promise<Observable<MyListItem[]>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });


    const url = `${environment.apiHostUrl}/my-lists`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<MyListItem[]>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }

  async getBookings(): Promise<Observable<Booking[]>> {
    let token = 'eyJraWQiOiI4clc5OTNMUnNhbVFqNGVwdCs4WmRQWVA2Mk1mOWxcLzlrNHI1Q0hCaXQ4MD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIzMmQ1YTRmNC02MDcxLTcwYTctZmM1OS1jNmM2YmU1OTkzNWYiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0xLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMV93bVVyMko5UHAiLCJjbGllbnRfaWQiOiIxcDJ0MDhhazhmODdzOXYzdWg2ZjNsYXZjbiIsIm9yaWdpbl9qdGkiOiJhNGY4MzM1Ni0yMDUwLTQ1ZWMtYjZmNi03ZThhNWY1MTdjMWMiLCJldmVudF9pZCI6IjI5YWVlMjM2LTRhN2ItNGJjOC05NzFhLWViMjFiOGU0NzYwMSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MjE3MzgzNDIsImV4cCI6MTcyMTc0MTk0MiwiaWF0IjoxNzIxNzM4MzQyLCJqdGkiOiI3Njk3MGMxYi04ZGFhLTRjMDYtYWZiNC1mODAyMDZmYzk4ZjYiLCJ1c2VybmFtZSI6Im1heGltLmhvcmJlbmtvQHVrci5uZXQifQ.ln0rOLdp6gepd-KScaZeDLlTHEIDgMLR3HjmMAZqeEeHcRJf8JzplPuuwrvM9V4Xc2GA5WNX0SEDEXXQphCuxWCoVeJ_hkS4dS7_3ugSti_2H6vJEiRp8NmEzcfTmfcgzFQoYkSyipRwys7sQAYVa9fSNFFABGC8ov8ugYvD16W87QCXeIgmy7atx5hyhUB-AAL9yxuFy1phYf_83cvsP5O66E2CrUoYrEUXAmS4lfv6EJ3XwAcjz48N_mSdOC-Gm_DXk7xgneSs1KcLQ2MpdB-0P1Wc4eqSsKgcLSin9gVHsaILz-9CJUJa2d6zIyfRxU9lqZ2W5ZtuvxRgRZCyhw';
    this.authService.accessToken$.subscribe(res => { token = res; });


    const url = `${environment.apiHostUrl}/reservations`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<Booking[]>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }

  async getNotifications_old(): Promise<Observable<any>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });


    const url = `${environment.apiHostUrl}/notifications`;

    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<any[]>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }

  async getNotifications(): Promise<Observable<any>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/notifications`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<any>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }

  async readNotification(timestamp: any) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });
    const url = `${environment.apiHostUrl}/notifications/mark-as-read?timestamp=${timestamp}`;

    await this.http.patch<any>(url, {}, {
      headers: {
          Authorization: `Bearer ${token}`
      }
    }).subscribe(result => {
      return result;
    });
  }


  async getVacancies(id: string): Promise<Observable<any>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/${id}/job-openings`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<any>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }

  async getApplications(): Promise<Observable<any>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/job-applications`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<any>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }

  async getApplication(id: string): Promise<Observable<any>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/job-applications/${id}`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<any>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }

  async readApplication(id: string): Promise<Observable<any>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/job-applications/${id}/read`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.put<any>(url,{}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }

  async sendVacancy(estId: string, vacancyId: string, data: object): Promise<Observable<any>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    let url = `${environment.apiHostUrl}/${estId}/job-openings/${vacancyId}/application`;

    return this.headers$.pipe(
      mergeMap((headers) => this.http.post<any>(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));

    // return this.http.post(`${environment.apiHostUrl}/${estId}/job-openings/${vacancyId}/application`, data, {
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    // }).subscribe(res => { })
  }

  async sendRequest(data: object): Promise<Observable<any>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    let url = `${environment.apiHostUrl}/for-restaurants/onboard-new`;

    return this.headers$.pipe(
      mergeMap((headers) => this.http.post<any>(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));

    // return this.http.post(`${environment.apiHostUrl}/${estId}/job-openings/${vacancyId}/application`, data, {
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    // }).subscribe(res => { })
  }

  async sendRequestExisting(data: object, establishmentId:string): Promise<Observable<any>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    let url = `${environment.apiHostUrl}/for-restaurants/manage-existing/${establishmentId}`;

    return this.headers$.pipe(
      mergeMap((headers) => this.http.post<any>(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));

    // return this.http.post(`${environment.apiHostUrl}/${estId}/job-openings/${vacancyId}/application`, data, {
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    // }).subscribe(res => { })
  }

  async getServices() {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/customer-service/tickets`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<any>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }

  async addService(subject: string, message: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/customer-service/tickets`;

    return this.http.post(url, {
      subject: subject,
      message: message
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })


  }

  async getTicket(ticket: any) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/customer-service/tickets/${ticket.id}`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<any>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }

  async sendMessage(message: string, ticketId: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/customer-service/tickets/${ticketId}/message`;

    return this.http.post(url, {
      message: message
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }

  async sendMessageApplication(message: string, applicationId: string) {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/job-applications/${applicationId}/message`;
    return this.http.post(url, {
      message: message
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe(res => { })
  }


  async getStoredUserProfile(): Promise<Observable<any>> {
    let token = '';
    this.authService.accessToken$.subscribe(res => { token = res; });

    const url = `${environment.apiHostUrl}/job-applications/stored-user-profile`;
    return this.headers$.pipe(
      mergeMap((headers) => this.http.get<any>(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })));
  }
}
