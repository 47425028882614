import { GeoPoint, Image } from './';

export interface Establishment {
  id: string;
  name: string;
  openFrom: string;
  openTill: string;
  city: string;
  address: string;
  phone: string;
  location: GeoPoint;
  delivery: boolean;
  pickup: boolean;
  tags: string[];
  coverImage: Image;
}
