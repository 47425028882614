import { register } from 'swiper/element/bundle';
import { Component } from '@angular/core';
import { ApiService, TrackingService } from './services';
import { Router } from '@angular/router';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  page = 'home'
  showSidemenu = false;

  modal = '';

  newNotifications = 0;
  isAuth = false;

  menus:any = [];

  constructor(
    public router: Router,
    private apiService:ApiService
  ) {

    this.checkMenu();
    this.checkIsAuth();

    if(!localStorage.getItem('menus')){
      localStorage.setItem('menus','[]')
    }

    router.events.subscribe(val => {
      let route = this.router.url.split('/');
      let lastRoute = route[route.length - 1];

      if(this.page != lastRoute){
        this.getNotifications();
        this.checkIsAuth();
        this.checkMenu();
      }

      if (lastRoute != '') {
        if (
          lastRoute == 'sign-up' ||
          lastRoute == 'login' ||
          lastRoute == 'registration' ||
          lastRoute == 'reset'
        ) {
          this.showSidemenu = false;
        } else {
          this.showSidemenu = true;
        }
      }

      this.page = lastRoute;
      // a1cc5262-557a-465a-afbc-43ecc8ac517e
    })

  }

  checkMenu(){
    this.menus = JSON.parse(localStorage.getItem('menus') as string);
  }

  checkIsAuth(){
    if(localStorage.getItem('amplify-signin-with-hostedUI')){
      this.isAuth = true;
    } else {
      this.isAuth = false;
    }
  }

  async getNotifications() {
    this.newNotifications = 0;

    (await this.apiService.getNotifications()).subscribe(async result => {
      let newNotifs = await result.items.filter(item => item.isNew == true);
      this.newNotifications = newNotifs.length;
    })
  }
}

