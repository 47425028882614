import { distinctUntilChanged, tap, from, switchMap, shareReplay } from 'rxjs';
import { Injectable } from '@angular/core';
import { Analytics } from '@aws-amplify/analytics';
import { AmplifyUser } from '@aws-amplify/ui';

import { AuthService } from '../auth';
import { TrackingEvent } from './enums';
import { TrackingEventOptions } from './models';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  endpointUpdated$ = this.authService.user$
    .pipe(
      distinctUntilChanged((prev, curr) => prev?.attributes?.email === curr?.attributes?.email),
      switchMap((user: AmplifyUser) => from(Analytics.updateEndpoint({
        address: user?.attributes?.email || '',
        userId: user?.attributes?.sub || '',
      }))),
      tap((r) => {
        console.log('Analytics endpoint updated', r);
      }),
      shareReplay(1),
    );

  constructor(private readonly authService: AuthService) {
    this.endpointUpdated$.subscribe();
  }

  trackEvent(name: TrackingEvent, options: TrackingEventOptions = {}) {
    console.log('options', options);
    this.endpointUpdated$.pipe(
      switchMap(() => from(Analytics.record({
        name,
        ...options,
      }))),
    ).subscribe(() => {
      console.log(`Event ${name} sent`);
    }, err => {
      console.log(err);
    });
  }
}
