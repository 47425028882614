export enum TrackingEvent {
  RestAppear = 'rest:appear',
  RestView = 'rest:view',
  RestLike = 'rest:like',
  RestUnlike = 'rest:unlike',
  RestDislike = 'rest:dislike',
  RestUndislike = 'rest:undislike',
  RestSave = 'rest:save',
  RestUnsave = 'rest:unsave',
  RestShare = 'rest:share',
  RestCall = 'rest:call',
  RestAddress = 'rest:address',

  MenuItemLike = 'mi:like',
  MenuItemUnlike = 'mi:unlike',
  MenuItemDislike = 'mi:dislike',
  MenuItemUndislike = 'mi:undislike',
  MenuItemSave = 'mi:save',
  MenuItemUnsave = 'mi:unsave',
  MenuItemShare = 'mi:share',

  CategoryAppear = 'cat:appear',
  CategoryView = 'cat:view',

  QcView = 'qc:view',

  SearchRequest = 'searc:req',

  MapRequest = 'map:req',
}