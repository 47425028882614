import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ApiService, UtilsService } from 'src/app/services';

@Component({
  selector: 'app-share',
  templateUrl: './share.page.html',
  styleUrls: ['./share.page.scss'],
})
export class SharePage implements OnInit {

  link: string = '';

  constructor(
    public modalCtrl: ModalController,
    private toastCtrl: ToastController
  ) { }

  ngOnInit() {
  }

  async copy() {
    navigator.clipboard.writeText(this.link);
    await(await this.toastCtrl.create({ message: 'Посилання на заклад скопійвано', duration: 1500 })).present();
  }

}

