import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then( m => m.SignUpPageModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
  },
  {
    path: 'quick-choice',
    loadChildren: () => import('./pages/quick-choice/quick-choice.module').then( m => m.QuickChoicePageModule),
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/eat/eat.module').then( m => m.EatPageModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./mobile/profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule),
  },
  {
    path: 'restaurant',
    loadChildren: () => import('./modals/restaurant/restaurant.module').then( m => m.RestaurantPageModule)
  },
  {
    path:'restaurant_page/:id',
    loadChildren: () => import('./pages/restaurantpage/restaurantpage.module').then(m => m.RestaurantpagePageModule)
  },
  {
    path:'menu_page/:id',
    loadChildren: () => import('./pages/menupage/menupage.module').then(m => m.MenupagePageModule)
  },
  {
    path: 'restaurant-modal',
    loadChildren: () => import('./restaurant-modal/restaurant-modal.module').then( m => m.RestaurantModalPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./modals/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'my-lists',
    loadChildren: () => import('./pages/saved/saved.module').then( m => m.SavedPageModule)
  },
  {
    path: 'bookings',
    loadChildren: () => import('./pages/bookings/bookings.module').then( m => m.BookingsPageModule)
  },
  {
    path: 'category/:id/:name',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'quickchoice/:id',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'restaurantpage',
    loadChildren: () => import('./pages/restaurantpage/restaurantpage.module').then( m => m.RestaurantpagePageModule)
  },
  {
    path: 'vacancy',
    loadChildren: () => import('./modals/vacancy/vacancy.module').then( m => m.VacancyPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'service',
    loadChildren: () => import('./pages/service/service.module').then( m => m.ServicePageModule)
  },
  {
    path: 'add-service',
    loadChildren: () => import('./modals/add-service/add-service.module').then( m => m.AddServicePageModule)
  },
  {
    path: 'ticket',
    loadChildren: () => import('./modals/ticket/ticket.module').then( m => m.TicketPageModule)
  },
  {
    path: 'jobapplications',
    loadChildren: () => import('./pages/applications/applications.module').then( m => m.ApplicationsPageModule)
  },
  {
    path: 'applications',
    loadChildren: () => import('./modals/application/application.module').then( m => m.ApplicationPageModule)
  },
  {
    path: 'vacancies',
    loadChildren: () => import('./modals/vacancies/vacancies.module').then( m => m.VacanciesPageModule)
  },
  {
    path: 'notifs',
    loadChildren: () => import('./mobile/notifs/notifs.module').then( m => m.NotifsPageModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./mobile/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'appl',
    loadChildren: () => import('./mobile/applications/applications.module').then( m => m.ApplicationsPageModule)
  },
  // {
  //   path: 'terms_of_use',
  //   loadChildren: () => import('./pages/rules/rules.module').then( m => m.RulesPageModule)
  // },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'rules-mobile',
    loadChildren: () => import('./mobile/rules-mobile/rules-mobile.module').then( m => m.RulesMobilePageModule)
  },
  {
    path: 'privacy-mobile',
    loadChildren: () => import('./mobile/privacy-mobile/privacy-mobile.module').then( m => m.PrivacyMobilePageModule)
  },
  {
    path: 'for',
    loadChildren: () => import('./for/for.module').then( m => m.ForPageModule)
  },
  {
    path: 'business',
    loadChildren: () => import('./pages/forrestaurants/forrestaurants.module').then( m => m.ForrestaurantsPageModule)
  },
  {
    path: 'terms_of_use',
    loadChildren: () => import('./info/rules/rules.module').then( m => m.RulesPageModule)
  },
  {
    path: 'privacy_policy',
    loadChildren: () => import('./info/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./info/aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'questions',
    loadChildren: () => import('./info/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path:'business-questions',
    loadChildren: () => import('./info/business-questions/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'request',
    loadChildren: () => import('./modals/request/request.module').then( m => m.RequestPageModule)
  },
  {
    path: 'requestexisting',
    loadChildren: () => import('./modals/requestexisting/requestexisting.module').then( m => m.RequestexistingPageModule)
  },
  {
    path: 'share',
    loadChildren: () => import('./modals/share/share.module').then( m => m.SharePageModule)
  },
  {
    path: 'menu_item/:rest_id/:id',
    loadChildren: () => import('./pages/menuitempage/menuitempage.module').then( m => m.MenuitempagePageModule)
  }



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(public router: Router){

    let loadRoute = this.router.url.split('/');

    console.log('loadRoute', loadRoute);

    if(loadRoute.includes('restaurant_page')){
      alert('restaurant_page');
    }

  }
}
