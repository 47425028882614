export interface ConfigItem {
    id: string;
    name: string;
}

export interface Config {
    genres: ConfigItem[];
    cuisines: ConfigItem[];
    types: ConfigItem[];
    occasions: ConfigItem[];
    neighborhoods: ConfigItem[];
}

