import { Like, Save, Establishment } from '.';

interface EstablishmentFeatureState {
  activated: boolean;
  menu: boolean;
  hiring:boolean;
  reservation: boolean;
}

interface EstablishmentImageState {
  isCoverImage:boolean;
  path:string;
}

export interface EstablishmentFull extends Establishment {
  added: string;
  workingHours: any;
  likes: Like[];
  saves: Save[];
  type: string[];
  images:EstablishmentImageState[];
  cuisine: string[];
  outdoorSeating: boolean;
  kidsFriendly: boolean;
  breakfastMenu: boolean;
  featureState: EstablishmentFeatureState
  lunchMenu: boolean;
  match:number;
  similarPlaces: Establishment[];
}
