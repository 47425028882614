import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Establishment, LikeType } from 'src/app/models';
import { RestaurantModalPage } from 'src/app/restaurant-modal/restaurant-modal.page';
import { ApiService, AuthService, UtilsService } from 'src/app/services';
import { RestaurantPage } from '../restaurant/restaurant.page';
import { SwipeService } from 'src/app/services/swipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
  providers: [SwipeService]
})
export class MenuPage implements OnInit {
  staticHostUrl = environment.staticHostUrl;
  menu: any = [];
  restaurant!: Establishment;
  selectedSection = { id: '', name: '', items: [] };
  showIndex = 0;
  selectedSectionIndex = 0;

  private swipeCoord?: [number, number];
  private swipeTime?: number;
  userId: string;

  constructor(
    private apiService: ApiService,
    public modalCtrl: ModalController,
    private authService: AuthService,
    private utils: UtilsService,
    private swipeService: SwipeService
  ) {
  }

  ionViewDidEnter() {
    this.menu.sections = this.menu.sections.filter(section => (section.status == 'visible' || section.status == 'not-available')).sort((a, b) => a.position - b.position);

    this.selectedSection = this.menu.sections[0];
    this.selectedSection.items = this.selectedSection.items.sort((a, b) => a.position - b.position);
  }

  async ngOnInit() {
    this.authService.user$.subscribe(res => {
      this.userId = res.attributes.sub;
    })
  }

  selectSegment(event) {
    this.showIndex = 0;
    this.selectedSectionIndex = event.detail.value;
    this.selectedSection = this.menu.sections[this.selectedSectionIndex];
    this.selectedSection.items = this.selectedSection.items.sort((a, b) => a.position - b.position);
  }

  selectSection(section, selectedSectionIndex) {
    this.showIndex = 0;
    this.selectedSectionIndex = selectedSectionIndex;
    this.selectedSection = section;
    this.selectedSection.items = this.selectedSection.items.sort((a, b) => a.position - b.position);
  }

  async like(item) {
    if (!this.userId) return this.utils.showAuthAlert();

    await this.apiService.likeItem(item.id, LikeType.Like);
    item.like = { type: 1 };
    //this.restaurantLike = LikeType.Like;
  }

  async dislike(item) {
    if (!this.userId) return this.utils.showAuthAlert();

    await this.apiService.likeItem(item.id, LikeType.Dislike);
    item.like = { type: -1 };
    //this.restaurantLike = LikeType.Dislike;
  }

  async deleteLike(item) {
    this.apiService.deleteLikeItem(item.id);
    item.like = null;
    //this.restaurantLike = 0;
  }

  async save(item) {
    if (!this.userId) return this.utils.showAuthAlert();

    await this.apiService.saveItem(item.id);
    item.save = true;
  }

  async deleteSave(item) {
    await this.apiService.deleteSaveItem(item.id);
    item.save = null;
  }

  async openRestaurant() {

    if (window.location.href.includes('menu')) {

      let cssClass = 'restaurantModal modalBottom modalContent';

      if (window.screen.width < 600) {
        cssClass = 'mobileModal'
      }

      const modal = await this.modalCtrl.create({
        componentProps: {
          restaurant: this.restaurant,
          restaurants: []
        },
        component: RestaurantPage,
        cssClass: cssClass
      });

      await modal.present();
    } else {
      this.modalCtrl.dismiss();
    }
  }

  prev() {
    this.selectedSectionIndex--;
    this.selectSection(this.menu.sections[this.selectedSectionIndex], this.selectedSectionIndex);
  }

  next() {
    this.selectedSectionIndex++;
    this.selectSection(this.menu.sections[this.selectedSectionIndex], this.selectedSectionIndex);
  }



  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    }
    else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;
      if (duration < 1000
        && Math.abs(direction[0]) > 30
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {

        if (direction[0] < 0) {
          if(this.showIndex < this.selectedSection.items.length-1) this.showIndex = this.showIndex + 1
        } else {
          //previous
          if(this.showIndex > 0) this.showIndex = this.showIndex - 1
        }
      }
    }
  }
}
